import {ARTICLES} from "../../../historyConfig"
import {useWindowSize} from "../../../hooks/useWindowSize"
import Container from "../../UI/Container/Container"
import Article from "../Article/Article"
import Slider from "../Slider/Slider"
import styles from "./History.module.scss"

export default function History() {
	const windowWidth = useWindowSize().width

	return (
		<section id='history'>
			<Container noPaddingBottom noPaddingTop>
				<div className={styles.wrapper}>
					<h2 className={styles.title}>ИСТОРИЯ БРЕНДА</h2>
					{windowWidth < 1024 ? (
						<Slider />
					) : (
						<div className={styles.list}>
							{ARTICLES.map((item, idx) => (
								<div key={idx}>
									<Article item={item} />
								</div>
							))}
						</div>
					)}
				</div>
			</Container>
			<picture>
				<source srcSet={"/images/main.jpg"} width={1920} height={520} media='(min-width: 768px)' />
				<img className={styles.image} src={`/images/main-m.jpg`} alt='' width={560} height={380} loading='lazy' />
			</picture>
		</section>
	)
}
