import Banner from "../../blocks/Banner/Banner"
import Header from "../../blocks/Header/Header"
import History from "../../blocks/History/History"
import Manifest from "../../blocks/Manifest/Manifest"
import styles from "./Home.module.scss"

export default function Home() {
	return (
		<>
			<div className={styles.headerWrapper}>
				<Header />
				<Banner />
			</div>
			<div className={styles.main}>
				<Manifest />
				<History />
			</div>
		</>
	)
}
