import styles from "./Logo.module.scss"

export default function Logo({isBlack}) {
	return (
		<a href='/' className={`${styles.wrapper} ${isBlack ? styles.black : ""}`}>
			<img alt='логотип' className={styles.image} src={`/images/logo${isBlack ? "-black" : ""}.svg`} width={133} height={20} />
			<div className={styles.divider} />
			<p>МАКСИМУМ</p>
		</a>
	)
}
