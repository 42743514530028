import styles from "./Container.module.scss"

export default function Container({children, noPaddingTop, noPaddingBottom, noPadding}) {
	return (
		<div
			className={`${styles.root} ${noPadding ? styles.noPadding : null} ${noPaddingTop ? styles.noPaddingTop : null} ${
				noPaddingBottom ? styles.noPaddingBottom : null
			}`}
		>
			{children}
		</div>
	)
}
