import Container from "../../UI/Container/Container"
import styles from "./Banner.module.scss"

export default function Banner() {
	return (
		<section className={styles.section}>
			<Container noPaddingTop noPaddingBottom>
				<div className={styles.container}>
					<div className={styles.textWrapper}>
						<h1 className={styles.title}>БРЕНД GEELY</h1>
					</div>
				</div>
			</Container>
		</section>
	)
}
