export const PHONE = {
	phone: "+7 (812) 501-27-12",
	href: "tel:+78125012712",
}

export const MENU = [
	{
		name: "Видение",
		section: "manifest",
	},
	{
		name: "История бренда",
		section: "history",
	},
]
