export const ARTICLES = [
	{
		title: "1997",
		text: "Основание Geely Auto. Компания входит в автоиндустрию с целью производства автомобилей, которые может позволить себе обычный человек, которые известны как продукты первого поколения (Эра 1.0). Geely стала первым в Китае частным автопроизводителем.",
		image: 0,
	},
	{
		title: "2007",
		text: "Geely Auto публично объявляет вступление в Эру 2.0 и стратегию глобального развития (Go Global). Компания совершила трансформацию – от конкуренции по цене к конкуренции в технологиях, качестве, потребительских преимуществах.",
		image: 1,
	},
	{
		title: "2014",
		text: "Geely Auto провозглашает Эру 3.0 с новой миссией и ценностями бренда, сфокусированными на создание современных, комфортабельных и безопасных автомобилей для каждого. Смена поколения моделей.",
		image: 2,
	},
	{
		title: "2020",
		text: "Автомобили, созданные на основе компактной модульной архитектуры CMA, ознаменовали начало производства инновационных продуктов Advanced Geely – моделей Эры 4.0",
		image: 3,
	},
]
