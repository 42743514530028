import Container from "../../UI/Container/Container"
import styles from "./Manifest.module.scss"

export default function Manifest() {
	return (
		<section id='manifest'>
			<Container noPaddingBottom noPaddingTop>
				<div className={styles.wrapper}>
					<div className={styles.textWrapper}>
						<h2>ВИДЕНИЕ</h2>
						<p>Так в китайском поэтическом стиле Geely определяет свое видение дальнейшего развития:</p>
						<p>
							«Я вижу лазурное море,
							<br /> Я смотрю на звездное небо
							<br /> И в огромной вселенной
							<br /> Вижу бесконечные возможности»
						</p>
						<p>
							Еще в 2010 году Председатель Geely Holding г-н Ли Шуфу очень образно предложил: «Прочно укоренившись на земле, мы смотрим на
							огромную вселенную. Там бесчисленное множество планет, и это символизирует наши возможности».
						</p>
						<h2>МИССИЯ</h2>
						<p>Создавать впечатления от вождения, которые превосходят ожидания</p>
						<h2>ЦЕННОСТИ</h2>
						<p>Ориентация на нужды людей. Инновации. Стремление к совершенству</p>
					</div>
					<picture>
						<source className={styles.image} srcSet={"/images/manifest.jpg"} width={1136} height={520} media='(min-width: 768px)' />
						<img className={styles.image} src={`/images/manifest-m.jpg`} alt='модели Geely' width={330} height={200} loading='lazy' />
					</picture>
				</div>
			</Container>
		</section>
	)
}
