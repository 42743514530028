import {MENU} from "../../../utils/constants"
import styles from "./Menu.module.scss"

export default function Menu() {
	const onMenuItem = (id) => {
		return (evt) => {
			evt.preventDefault()
			window.scrollTo({
				top: document.getElementById(id).getBoundingClientRect().top + window.scrollY,
				behavior: "smooth",
			})
		}
	}
	return (
		<div className={styles.container}>
			<ul className={styles.list}>
				{MENU.map((item) => (
					<li>
						<p className={styles.item} onClick={onMenuItem(item.section)}>
							{item.name}
						</p>
					</li>
				))}
			</ul>
		</div>
	)
}
