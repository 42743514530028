import "./assets/styles/style.scss"
import "./assets/styles/globals.scss"

import {Routes, Route} from "react-router-dom"

import PrivacyPolicy from "./components/pages/PrivacyPolicy/PrivacyPolicy"
import Home from "./components/pages/Home/Home"
import Footer from "./components/blocks/Footer/Footer"

export default function App() {
	return (
		<div className='App'>
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='privacy-policy' element={<PrivacyPolicy />} />
			</Routes>
			<Footer />
		</div>
	)
}
