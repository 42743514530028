import styles from "./Article.module.scss"

export default function Article({item}) {
	const {title, text, image} = item

	return (
		<div className={styles.wrapper}>
			<div className={styles.textWrapper}>
				<h3>{title}</h3>
				<p>{text}</p>
			</div>
			<img className={styles.image} src={`/images/history/${image}.jpg`} alt='' width={552} height={368} />
		</div>
	)
}
